<app-nav-header></app-nav-header>

<section class="body">
    <div class="container-fluid">

        <app-side-nav></app-side-nav>

        <div class="main">


                <!-- <section class="mini__header">
                    <div class="container-fluid">

                        <div class="mini__headerBody">

                                <div class="nav nav-tabs" id="nav-tab" role="tablist">

                                    <ul  class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                          <a routerLink="/dashboard-pagejobs" class="nav-link active" id="jobs-tab" data-bs-toggle="pill" data-bs-target="#jobs" role="tab" aria-controls="jobs" aria-selected="true">Jobs</a>
                                        </li>

                                      </ul>

                                </div>

                              <a routerLink="/dashboard-pagejobsposting" class="button">Add Job Posting</a>

                        </div>

                    </div>
                    <div class="container-fluid">
                      <div class="alert alert-success" role="alert" *ngIf="submitSuccess">
                        Successfully add the Job. Click publish to allow all candidates to view.
                      </div>
                      <div class="alert alert-danger" role="alert" *ngIf="submitFailure">
                        Error! {{errorMessage}}
                      </div>
                      <div class="alert alert-success" role="alert" *ngIf="publishSuccess">
                        Success!
                      </div>
                    </div>
                </section> -->

                <section>
                    <div class="container-fluid">


                      <!-- <mat-form-field appearance="fill">
                        <mat-label>Input</mat-label>
                        <input matInput>
                      </mat-form-field>
                      <mat-form-field appearance="fill">
                        <mat-label>Select</mat-label>
                        <mat-select>
                          <mat-option value="one">First option</mat-option>
                          <mat-option value="two">Second option</mat-option>
                        </mat-select>
                      </mat-form-field> -->

                        <div class="card card__form">
                            <form [formGroup]="form" class="top__form">
                                <div class="row">
                                  <div class="col-md-4">

                                    <div class="form-group">
                                      <span>Title</span>
                                      <input formControlName="title" type="text" class="form-control" placeholder="Title">
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <span>Company</span>
                                    <select formControlName="company" [compareWith]="comparingFn" class="form-select">
                                      <option disabled value="" hidden [selected]=true class="selected">Company</option>
                                      <option *ngFor="let comp of companies" [ngValue]="comp">{{comp.name}}</option>
                                    </select>
                                  </div>
                                  <div class="col-md-4">
                                    <span>Division</span>
                                    <select [compareWith]="comparingFn" formControlName="division" class="form-select">
                                      <option disabled value="" hidden [selected]=true class="selected" >Division</option>
                                      <option *ngFor="let division of divisions" [ngValue]="division">{{division.name}}</option>
                                    </select>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col-md-4">
                                    <span>Department</span>
                                    <select [compareWith]="comparingFn" formControlName="department" class="form-select">
                                      <option disabled value="" hidden [selected]=true class="selected" >Department</option>
                                      <option *ngFor="let department of departments" [ngValue]="department">{{department.name}}</option>
                                    </select>
                                  </div>



                                  <div class="col-md-4">
                                    <span>Job Type</span>
                                    <select formControlName="job_type" class="form-select">
                                      <option disabled value="" hidden [selected]=true class="selected">Job Type</option>
                                      <option *ngFor="let job_type of job_types" [ngValue]="job_type" (change)="changeJob($event)" >{{job_type}}</option>
                                    </select>
                                  </div>
                                  <div class="col-md-4">
                                    <span>Location</span>
                                    <select [compareWith]="comparingFn" formControlName="location" class="form-select">
                                      <option disabled value="" hidden [selected]=true class="selected" >Location</option>
                                      <option *ngFor="let location of locations" [ngValue]="location" (change)="changeLocation($event)" >{{location.name}}</option>
                                    </select>
                                  </div>

                                </div>

                                <div class="row">


                                  <div class="col-md-4">
                                    <span>Status</span>
                                    <select formControlName="job_status" class="form-select">
                                      <option disabled value="" hidden [selected]=true class="selected">Status</option>
                                      <option *ngFor="let status of statuses" [ngValue]="status">{{status}}</option>
                                    </select>
                                  </div>



                                  <div class="col-md-4">
                                    <span>Audience</span>
                                    <select formControlName="audience" class="form-select">
                                      <option disabled value="" hidden [selected]=true class="selected">Audience</option>
                                      <option *ngFor="let audi of audiences" [ngValue]="audi">{{audi}}</option>
                                    </select>
                                  </div>

                                  <div class="col-md-4">
                                    <span>End Date</span>
                                    <input formControlName="apply_by" type="date" class="form-control" placeholder="Date">
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col-md-4">
                                    <span>Number of vacancies</span>
                                    <input formControlName="vacancies" type="number" class="form-control" placeholder="Vacancies" min="1" >
                                  </div>
                                </div>





                                <!-- <div action="" class="textInput__form">
                                    <div class="form-group green-border-focus">
                                        <textarea formControlName="description" class="form-control" id="exampleFormControlTextarea5" rows="7" placeholder="Job Description" ></textarea>
                                      </div>
                                </div> -->

                                <div class="textInput__form mb-4">


                                      <angular-editor formControlName="description" [config]="config"></angular-editor>


                                  </div>


                            <div action="" class="bot__form">
                                <div class="row">
                                    <div class="col-md-12">


                                      <!-- <mat-form-field appearance="outline">
                                        <mat-label>Skills</mat-label>
                                        <mat-select formControlName="skills">
                                          <mat-option *ngFor="let skill of skills" [value]="skill.id" >{{skill.name}}</mat-option>
                                        </mat-select>
                                      </mat-form-field> -->



                                      <span>Skills</span>
                                      <div class="d-flex">
                                        <!-- <select >
                                          <option disabled value="" hidden [selected]=true class="selected">Select</option>
                                          <option *ngFor="let skill of skills" [ngValue]="skill.id" >{{skill.name}}</option>
                                        </select> -->
                                        <ng-select [compareWith]="comparingFn" [multiple]="true" placeholder="Select skills" formControlName="skills" class="form-select" style="width: 80%">
                                          <ng-option *ngFor="let skill of skills" [value]="skill" >{{skill.name}}</ng-option>
                                        </ng-select>
                                        <button type="button" class="btn btn-info add-save p-2" data-bs-toggle="modal" data-bs-target="#exampleModal">add</button>
                                      </div>
                                    </div>


                                    <div class="col-md-12">
                                      <span>Experience</span>
                                      <div class="d-flex">
                                        <ng-select [multiple]="true" [compareWith]="comparingFn" placeholder="Select experiences" formControlName="experience" class="form-select" style="width: 80%">
                                          <ng-option *ngFor="let experience of experiences" [value]="experience">{{experience.name}}</ng-option>
                                        </ng-select>

                                        <button type="button" class="btn btn-info add-save p-2" data-bs-toggle="modal" data-bs-target="#exampleModal1">add</button>
                                      </div>
                                    </div>
                                  </div>
                            </div>

                            <!-- <div class="toggle__switch">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label class="switch">
                                            <input type="checkbox">
                                            <span class="slider round"></span>
                                          </label>
                                    </div>
                                </div>
                            </div> -->

                            <div class="form__buttn">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-3">
                                          <button type="button" class="btn btn-success saveBtns"(click)="submit()">Save</button>
                                        </div>
                                        <div class="col-md-3">
                                          <button type="button" class="btn btn-info add-save p-2" data-bs-toggle="modal" data-bs-target="#exampleModal2">Preview</button>
                                          <!-- <button type="submit" class="btn btn-primary saveBtns" (click)="publish()">Preview</button> -->
                                        </div>
                                        <div class="col-md-3">
                                            <button type="submit" class="btn btn-danger saveBtns" (click)="cancel()">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>

                        </div>

                    </div>
                </section>


        </div>
    </div>

</section>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="exampleModalLabel"><b>Add Skill</b></h6>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">

        <form #skillForm="ngForm">
          <div class="form-group">
            <input class="form-control"
            placeholder="skill name"
            [(ngModel)]="skillForm.name"
            #name="ngModel"
            id="input-name"
            name="name"
            required>
          </div>
        </form>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="saveSkills(skillForm)" [disabled]="!skillForm.valid">Submit</button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" id="close-skill">Close</button>
      </div>
    </div>
  </div>
</div>




<div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="exampleModalLabel"><b>Add Experience</b></h6>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form #experienceForm="ngForm">
          <div class="form-group">
            <input class="form-control"
            placeholder="Experience name"
            [(ngModel)]="experienceForm.name"
            #name="ngModel"
            id="input-name"
            name="name"
            required>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="saveExperience(experienceForm)" [disabled]="!experienceForm.valid">Submit</button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" id="close-exp">Close</button>
      </div>
    </div>
  </div>
</div>



<div class="modal fade bd-example-modal-lg" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="exampleModalLabel"><b>Job Details</b></h6>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="container-fluid application__details">
          <div class="row">
            <div class="col-md-12">
              <h4>{{newJob?.title}} - {{newJob?.company?.name}}</h4>
              <div class="sub-title"><p>{{newJob?.division?.name}}</p></div>
              <div class="sub-title"><p>{{newJob?.department?.name}}</p></div>
              <div class="sub-title"><p>{{newJob?.job_type}}</p></div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <h6> <u>Description</u> </h6>
              <p [innerHTML]="newJob?.description" class="desc-text"></p>
            </div>
            <div class="col-md-12">
              <h6> <u>Skills</u> </h6>
              <ul>
                <li *ngFor="let skill of newJob?.skills">{{skill}}</li>
              </ul>
            </div>
            <div class="col-md-12">
              <h6> <u>Experience</u> </h6>
              <ul>
                <li *ngFor="let exp of newJob?.experience">{{exp}}</li>
              </ul>
            </div>
            <div class="col-md-12">
              <h6> <u>More Details</u> </h6>
              <p class="p-2">Job Status: {{newJob?.job_status}}</p>
              <p class="p-2">Audience: {{newJob?.audience}}</p>
              <p class="p-2">Location: {{newJob?.location}}</p>
              <p class="p-2">Vacancies: {{newJob?.vacancies}}</p>
              <p class="p-2">APPLY BY: {{newJob?.apply_by}}</p>
            </div>




          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="publish()">Publish</button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" id="close-exp">Close</button>
      </div>
    </div>
  </div>
</div>
