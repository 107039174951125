import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';

import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  form: FormGroup;
  submitted = false;
  userForm: any;
  errorMessage: string;


  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private toastrService: ToastrService,
  ) { }





  ngOnInit(): void {

    this.form = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      phone_number: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required,],
      confirm_password: ['', Validators.required],
    })

  }

  get f() { return this.form.controls; }


  submit(): void{
    this.submitted = true;

    const payload = {
      first_name: this.form.get('first_name').value,
      last_name: this.form.get('last_name').value,
      phone_number: this.form.get('phone_number').value,
      email: this.form.get('email').value,
      password: this.form.get('password').value,
      confirm_password: this.form.get('confirm_password').value,
    }

    this.authService.signup(payload)
    .subscribe(
      () => {
        this.toastrService.success('Success', 'Your Account Registration a Success');
        this.router.navigate(['/login'])
      },
      (error: HttpErrorResponse) => {
        if (error.error !== undefined) {
          const errorObject = error.error;

          for (const [key, value] of Object.entries(errorObject)) {
            this.errorMessage = key + ' - ' + value;
          }
        }
        this.toastrService.warning('Error', this.errorMessage);
      }
    )
  }

}
