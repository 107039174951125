import { Component, OnInit } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { faSmile, faLayerGroup, faUsers, faComments } from '@fortawesome/free-solid-svg-icons';
import { DashService } from '../shared/services/dash.service';


@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.css']
})
export class JobListComponent implements OnInit {

  alive = true;

  jobs = [];

  faSmile = faSmile;
  faLayerGroup = faLayerGroup;
  faUsers = faUsers;
  faComments = faComments;

  constructor(
    private dashService: DashService,
    ) { }

  ngOnInit(): void {
    this.fetcAllJobs();
  }


  fetcAllJobs() {
    this.dashService.loadJobs()
      .pipe(takeWhile(() => this.alive))
      .subscribe(data => {
        this.jobs = data;
      });
  }
}
