<app-nav-header></app-nav-header>

<section class="body">
    <div class="container-fluid">

      <app-loader></app-loader>
        <app-side-nav></app-side-nav>

        <div class="main">

                <section class="mini__header jobs__SH">
                    <div class="container-fluid p-2">

                        <div class="mini__headerBody">

                                <div class="nav nav-tabs" id="nav-tab" role="tablist">

                                    <ul  class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                          <a class="nav-link active" id="jobs-tab" data-bs-toggle="pill" data-bs-target="#jobs" role="tab" aria-controls="jobs" aria-selected="true" routerLink="/hr-jobs">Jobs</a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                          <a class="nav-link" id="candidates-tab" data-bs-toggle="pill" data-bs-target="#candidates" role="tab" aria-controls="candidates" aria-selected="false" routerLink="/candidates">Candidates</a>
                                        </li>
                                        <!-- <li class="nav-item" role="presentation">
                                          <a class="" id="talent-tab" data-bs-toggle="pill" data-bs-target="#talent"  role="tab" aria-controls="talent" aria-selected="false">Talent Pool</a>
                                        </li> -->
                                      </ul>

                                </div>

                              <a routerLink="/create-job" class="button btn btn-primary">Add Job</a>

                        </div>

                    </div>
                </section>

                <div class="container-fluid jobs-table">
                  <div class="row">
                    <div class="col-md-6"><h6>Jobs: {{numberOfJobs}}</h6></div>
                    <div class="col-md-6 text-end">
                      <h6><div class="dropdown">
                        Records:

                        <button class="dropDown dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                          25
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li><a class="dropdown-item" >10</a></li>
                          <li><a class="dropdown-item" >15</a></li>
                          <li><a class="dropdown-item" >5</a></li>
                        </ul>
                      </div>
                    </h6>
                  </div>

                  <div class="col-md-12">
                    <div class="card">

                      <div class="card-body">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">NO.</th>
                              <th scope="col">JOB TITLE</th>
                              <th scope="col">COMPANY</th>
                              <th scope="col">DIVISION</th>
                              <th scope="col">DEPARTMENT</th>
                              <th scope="col">NO. OF VACANCIES</th>
                              <th scope="col">NO. OF CANDIDATES</th>
                              <th scope="col">AUDIENCE</th>
                              <th scope="col">STATUS</th>
                              <th scope="col">EDIT</th>
                              <th scope="col">DELETE</th>
                              <!-- <th>DETAILS</th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor='let x of jobs; index as i'>
                              <td>{{i + 1}}</td>
                              <td>{{x.title}}</td>
                              <td>{{x.company?.name}}</td>
                              <td>{{x.division?.name}}</td>
                              <td>{{x.department?.name}}</td>
                              <td>{{x.vacancies}}</td>
                              <td> <a routerLink="/candidates/{{x.id}}">{{x.number_of_candidates}}</a></td>
                              <td>{{x.audience}}</td>
                              <td>
                                <button *ngIf="x.job_status === 'OPEN' " type="button" name="button" class="btn btn-info p-1">{{x.job_status}}</button>
                                <button *ngIf="x.job_status === 'CLOSED' " type="button" name="button" class="btn btn-warning p-1">{{x.job_status}}</button>
                              </td>

                              <td><fa-icon [icon]="faEdit" style="color: green" (click)="editJob(x.id)"></fa-icon></td>
                              <td><fa-icon [icon]="faTrash" style="color: red" (click)="removeJob(x.id)"></fa-icon></td>

                              <!-- <td><a routerLink="/job/{{x?.id}}"> <i class="far fa-eye"></i> </a></td> -->
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

                        <!-- <div class="tab-content" id="nav-tabContent">
                          <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                          </div>
                        </div> -->

                  <!-- <div style="margin-top: 10vh;" class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="jobs" role="tabpanel" aria-labelledby="jobs-tab">
                    </div>
                    <div class="tab-pane fade" id="candidates" role="tabpanel" aria-labelledby="candidates-tab">...candidates</div>
                    <div class="tab-pane fade" id="talent" role="tabpanel" aria-labelledby="talent-tab">...talanta</div>
                  </div> -->


        </div>
    </div>

</section>
