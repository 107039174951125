<section class="footer">
  <footer>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="row justify-content-center">
            <div class="col-md-3">
              <div class="row">
                <div class="col-md-12">
                  <h6>Sign Up to our News Letters</h6>
                </div>
                <div class="col-md-12 newsletter">
                  <input type="text" class="form-control" placeholder="">
                  <input type="submit" class="bTn" value="SEND">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="row justify-content-center">
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-4">
                  <h6>Social Media:</h6>
                </div>
                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-3"><i class="fab fa-facebook-f"></i></div>
                    <div class="col-md-3"><i class="fab fa-instagram"></i></div>
                    <div class="col-md-3"><i class="fab fa-twitter"></i></div>
                    <div class="col-md-3"><i class="fab fa-linkedin-in"></i></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <h6>Contacts:</h6>
            </div>
            <div class="col-md-4">
              <h6>Copyright &copy; 2012-2021 Adrian Kenya Limited</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</section>
