<section class="password-reset">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="card">
                    <div class="row justify-content-center">
                        <div class="col-md-8">
                            <h6>Request Password Reset</h6>
                            <form action="">
                                <input type="text" class="form-control email" placeholder="Email ID">
                                <div class="row justify-content-center">
                                    <div class="col-md-4">
                                        <input type="submit"class="submit">
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
