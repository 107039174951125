import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { hrtime } from 'process';
import { ApplicationPageComponent } from './application-page/application-page.component';
import { CandidateProfileComponent } from './dashboard-page/candidate-profile/candidate-profile.component';
import { CandidatesComponent } from './dashboard-page/candidates/candidates.component';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { JobsPostingComponent } from './dashboard-page/create-job/create-job.component';
import { FaqpageComponent } from './faqpage/faqpage.component';

import { HomepageComponent } from './candiate-homepage/homepage.component';
import { JobListComponent } from './job-list/job-list.component';
import { LoginComponent } from './login/login.component';
import { PasswordResetComponent } from './login/password-reset/password-reset.component';
import { ProfileComponent } from './profile/profile.component';
import { ApplicantGuard } from './shared/interceptors/applicant.guard';
import { AuthGuard } from './shared/interceptors/auth.guard';
import { HrGuard } from './shared/interceptors/hr.guard';
import { SignupComponent } from './signup/signup.component';
import { ApplicationsListComponent } from './dashboard-page/applications-list/jobs.component';

const routes: Routes = [
  // CANDIDATE ROUTES
  {
    path: '',
    component: JobListComponent,
  },
  {
    path: 'jobs',
    component: JobListComponent,
  },
  {
    path: 'home',
    // canActivate: [AuthGuard],
    component: HomepageComponent,
  },
  {
    path: 'home/:id',
    // canActivate: [AuthGuard],
    component: HomepageComponent,
  },
  {
    path: 'profile',
    // canActivate: [AuthGuard],
    component: ProfileComponent,
  },
  {
    path: 'profile/:jobId/:candidateId',
    // canActivate: [AuthGuard],
    component: ProfileComponent,
  },
  {
    path: 'faq',
    component: FaqpageComponent,
  },
  {
    path: 'application',
    component: ApplicationPageComponent,
  },
  {
    path: 'application/:id',
    component: ApplicationPageComponent,
  },
  // HR ROUTES
  {
    path: 'hr-page',
    canActivate: [AuthGuard, HrGuard],
    component: DashboardPageComponent,
  },
  {
    path: 'hr-jobs',
    canActivate: [AuthGuard],
    component: ApplicationsListComponent,
  },
  {
    path: 'create-job',
    canActivate: [AuthGuard, HrGuard],
    component: JobsPostingComponent,
  },
  {
    path: 'create-job/:id',
    canActivate: [AuthGuard, HrGuard],
    component: JobsPostingComponent,
  },
  {
    path: 'candidates',
    // canActivate: [AuthGuard],
    component: CandidatesComponent,
  },
  {
    path: 'candidates/:id',
    // canActivate: [AuthGuard],
    component: CandidatesComponent,
  },
  {
    path: 'candidate-profile/:id',
    // canActivate: [AuthGuard],
    component: CandidateProfileComponent,
  },
  // Basic System Routes
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'password-reset',
    component: PasswordResetComponent,
  },
  {
    path: 'signup',
    component: SignupComponent,
  },

  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
