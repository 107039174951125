import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

// This service only belongs to this component

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }
}
