<app-navbar></app-navbar>
<section id="hero">
  <div class="hero container"></div>
</section>

<section class="application">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <button class="btn btn-primary float-right" (click)="scroll(applyJob)">
          APPLY NOW
        </button>
      </div>
    </div>
    <div class="container-fluid application__details">
      <div class="row">
        <div class="col-md-12">
          <h4>{{ jobDetails?.title }} - {{ jobDetails?.company?.name }}</h4>
          <div class="sub-title">
            <p>{{ jobDetails?.job_type }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h6><u>Description</u></h6>
          <p [innerHTML]="jobDetails?.description" class="desc-text"></p>
        </div>
        <!-- <div class="col-md-12">
          <h6> <u>Skills</u> </h6>
          <p [innerHTML]="jobDetails?.description" class="desc-text"></p>
        </div>
        <div class="col-md-12">
          <h6> <u>Experience</u> </h6>
          <p [innerHTML]="jobDetails?.description" class="desc-text"></p>
        </div> -->
      </div>
    </div>

    <!-- job application form starts -->
    <form
      [formGroup]="applicationForm"
      enctype="multipart/form-data"
      [hidden]="applicationDetail !== undefined"
      (ngSubmit)="submit()"
    >
      <div class="container-fluid application__form">
        <div #applyJob class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-6">
                <h6><b>Apply for this Job</b></h6>
              </div>
            </div>
          </div>

          <div class="card-body main">
            <div class="form-main">
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-3">
                      <p>First Name <sup>*</sup></p>
                    </div>
                    <div class="col-md-9">
                      <input
                        formControlName="first_name"
                        type="text"
                        class="form-control"
                        id="first_name"
                        minlength="3"
                      />
                      <div
                        class="makeRed"
                        *ngIf="
                          first_name.invalid &&
                          (first_name.dirty || first_name.touched)
                        "
                      >
                        <div *ngIf="first_name.errors?.required">Required</div>
                        <div *ngIf="first_name.errors?.minlength">
                          At least 3 characters
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <p>Email <sup>*</sup></p>
                    </div>
                    <div class="col-md-9">
                      <!-- test email field  -->
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1"
                            >@</span
                          >
                        </div>
                        <input
                          formControlName="email"
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <!-- test email field  -->

                      <div
                        class="makeRed"
                        *ngIf="email.invalid && (email.dirty || email.touched)"
                      >
                        <div *ngIf="email.errors?.required">Required</div>
                        <div *ngIf="email.errors?.required">
                          Email should be active and valid for contact
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-3">
                      <p>Last Name <sup>*</sup></p>
                    </div>
                    <div class="col-md-9">
                      <input
                        formControlName="last_name"
                        type="text"
                        class="form-control"
                        minlength="3"
                      />
                      <div
                        class="makeRed"
                        *ngIf="
                          last_name.invalid &&
                          (last_name.dirty || last_name.touched)
                        "
                      >
                        <div *ngIf="last_name.errors?.required">Required</div>
                        <div *ngIf="last_name.errors?.minlength">
                          At least 3 characters
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <p>Phone <sup>*</sup></p>
                    </div>
                    <div class="col-md-9">
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text">+</span>
                        </div>
                        <input
                          formControlName="phone_number"
                          type="tel"
                          class="form-control"
                          minlength="12"
                          maxlength="12"
                          (keypress)="keyPressNumbers($event)"
                        />
                        <div class="input-group-append"></div>
                      </div>
                      <!-- error flag -->
                      <div
                        class="makeRed"
                        *ngIf="
                          phone_number.invalid &&
                          (phone_number.dirty || phone_number.touched)
                        "
                      >
                        <div *ngIf="phone_number.errors?.required">
                          Required
                        </div>
                        <div *ngIf="phone_number.errors?.minlength">
                          Incomplete
                        </div>
                      </div>
                      <!-- error flag -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row form__files">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-2">
                    <p>Resume/CV <sup>*</sup></p>
                  </div>
                  <div class="col-md-10">
                    <input
                      type="file"
                      class="form-control"
                      (change)="uploadResume($event)"
                      accept=".pdf"
                    />
                    <div
                      class="makeRed"
                      *ngIf="
                        last_name.invalid &&
                        (last_name.dirty || last_name.touched)
                      "
                    >
                      <div *ngIf="last_name.errors?.required">Required:</div>
                      <div *ngIf="last_name.errors?.required">
                        Please provide PDF(s)
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-2">
                    <p>Cover Letter</p>
                  </div>
                  <div class="col-md-10">
                    <input
                      type="file"
                      class="form-control"
                      (change)="uploadCoveletter($event)"
                      accept=".pdf,.doc"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row web__url">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-2"><p>LinkedIn URL</p></div>
                  <div class="col-md-10">
                    <input
                      formControlName="linkedIn_URL"
                      type="url"
                      class="form-control"
                    />
                  </div>
                  <div class="col-md-2"><p>Personal Website</p></div>
                  <div class="col-md-10">
                    <input
                      formControlName="personal_website_URL"
                      type="url"
                      class="form-control"
                    />
                  </div>
                  <div class="col-md-2"><p>Github URL</p></div>
                  <div class="col-md-10">
                    <input
                      formControlName="gitHub_URL"
                      type="url"
                      class="form-control"
                    />
                  </div>
                  <div class="col-md-2"><p>Location</p></div>
                  <div class="col-md-10">
                    <input
                      formControlName="location"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row employer">
              <div class="col-md-11 ml-4">
                <div class="card">
                  <div class="content">
                    <div
                      formArrayName="employers"
                      *ngFor="
                        let item of applicationForm.get('employers')[
                          'controls'
                        ];
                        let i = index
                      "
                    >
                      <div [formGroupName]="i">
                        <div class="row">
                          <p>Employer</p>
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-12">
                                <input
                                  formControlName="job_title"
                                  type="text"
                                  class="form-control mb-2"
                                  placeholder="Job Title"
                                />
                              </div>
                              <div class="col-md-12">
                                <input
                                  formControlName="company"
                                  type="text"
                                  class="form-control mt-2"
                                  placeholder="Company"
                                />
                              </div>
                              <div class="col-md-6">
                                <label for="">Start Date</label>
                                <input
                                  formControlName="start_date"
                                  type="date"
                                  class="form-control"
                                  placeholder="Start Date"
                                  Start
                                  Date
                                />
                              </div>
                              <div class="col-md-6">
                                <label for="">End Date</label>
                                <input
                                  formControlName="end_date"
                                  type="date"
                                  class="form-control"
                                  placeholder="End Date"
                                />
                              </div>
                              <div class="col-md-12">
                                <textarea
                                  formControlName="summary"
                                  class="form-control mt-4"
                                  rows="3"
                                  placeholder="Summary"
                                ></textarea>
                              </div>
                              <div class="col-md-12">
                                <i
                                  (click)="deleteJob(i)"
                                  class="fas fa-trash-alt float-right"
                                ></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <button
                        type="button"
                        (click)="addJob()"
                        class="btn btn-primary float-left"
                      >
                        <i class="fas fa-plus"></i>
                        Add Employer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row employer">
              <div class="col-md-11 ml-4">
                <div class="card">
                  <div class="content">
                    <div
                      formArrayName="educations"
                      *ngFor="
                        let content of applicationForm.get('educations')[
                          'controls'
                        ];
                        let i = index
                      "
                    >
                      <div [formGroupName]="i">
                        <div class="row">
                          <h6>Education</h6>
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-12">
                                <input
                                  formControlName="degree"
                                  type="text"
                                  class="form-control mb-2"
                                  placeholder="Degree/ etc."
                                />
                              </div>
                              <div class="col-md-12">
                                <input
                                  formControlName="field_of_study"
                                  type="text"
                                  class="form-control mt-2"
                                  placeholder="Field of Study/Major"
                                />
                              </div>
                              <div class="col-md-12">
                                <input
                                  formControlName="institution"
                                  type="text"
                                  class="form-control mt-4"
                                  placeholder="Institute/School Name"
                                />
                              </div>
                              <div class="col-md-6">
                                <label for="">Start Date</label>

                                <input
                                  formControlName="start_date"
                                  type="date"
                                  class="form-control"
                                  placeholder="Start Date"
                                />
                              </div>
                              <div class="col-md-6">
                                <label for="">End Date</label>

                                <input
                                  formControlName="end_date"
                                  type="date"
                                  class="form-control"
                                  placeholder="End Date"
                                />
                              </div>
                              <div class="col-md-12">
                                <i
                                  (click)="deleteEdu(i)"
                                  class="fas fa-trash-alt float-right"
                                ></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <button
                        type="button"
                        (click)="addEdu()"
                        class="btn btn-primary float-left"
                      >
                        <i class="fas fa-plus"></i>
                        Add Education
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row btmfom">
          <div class="col-md-12">
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="exampleCheck1"
              />
              <label class="form-check-label" for="exampleCheck1"
                >Adrian Kenya has my consent to collect, store and process my
                data for the purposes of considering me for employment, and for
                upto 365 days thereafter.</label
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 pt-4 text-right">
            <button type="submit" class="btn btn-primary">
              SUBMIT APPLICATION
            </button>
          </div>
        </div>
      </div>
    </form>
    <div class="card" [hidden]="applicationDetail === undefined">
      <div class="card-body">
        <p class="text-danger">
          <b>You have submitted your application successfully</b>
        </p>
      </div>
    </div>
  </div>
</section>
