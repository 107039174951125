import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-faqpage',
  templateUrl: './faqpage.component.html',
  styleUrls: ['./faqpage.component.css']
})
export class FaqpageComponent implements OnInit {

  loggedInApplicant = JSON.parse(localStorage.getItem('loggedInApplicant'))

  constructor(private authService: AuthService,private router: Router,) { }

  ngOnInit(): void {
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

}
