import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../shared/services/auth.service';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  fieldTextType: boolean;




  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required,],
    });
  }


  get f() { return this.loginForm.controls; }


toggleFieldTextType() {
  this.fieldTextType = !this.fieldTextType;
}

  submit(): void{
    this.submitted = true;
    this.authService.login(this.loginForm.get('email').value, this.loginForm.get('password').value)
    .subscribe(
      data => {
        this.loginForm.reset()

        if (data.user.is_candidate) {
          this.router.navigate(['jobs']);
        } else {
          this.router.navigate(['/dashboard-page']);
        }
        this.authService.setLoggedIn(true);
        localStorage.setItem('loggedInApplicant', JSON.stringify(data));
      },

      (error: HttpErrorResponse) => {

        if (error.error !== undefined) {
          const errorMessage = error.error.detail;
          this.toastrService.warning('Error', errorMessage);
        }
      }
    );
  }

}
