import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthGuard } from "./auth.guard";


@Injectable({ providedIn: 'root' })
export class HrGuard implements CanActivate{

    constructor (private router: Router,
        private authguard: AuthGuard,){}


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {


      if (!this.authguard.isHR) {
        this.router.navigate(['/jobs']);
      }

      return this.authguard.isHR

    }

}
