<div class="sidenav">
    <!-- <a routerLink="/hr-page" routerLinkActive="active-link" title="Home">
      <a><i class="fas fa-bars"></i> Home</a>
    </a> -->
  <a routerLink="/hr-page" routerLinkActive="active-link" title="Dashboard">
    <a><i class="fas fa-columns"></i> Dashboard</a>
  </a>
  <a routerLink="/hr-jobs" routerLinkActive="active-link" title="Jobs">
    <a><i class="fas fa-briefcase"></i> Jobs</a>
    <div class="bc">
      <!-- <a><i class="fas fa-ellipsis-v"></i></a> -->
    </div>
  </a>
  <!-- <a routerLink="/candidates" routerLinkActive="active-link" title="Employees">
    <a><i class="fas fa-user-friends"></i> Employees</a>
    <div class="bc">
      <a><i class="fas fa-ellipsis-v"></i></a>
    </div>
  </a> -->
</div>
