<div class="row">
    <div class="col-md-12">
        <div id="chart">
            <apx-chart
              [series]="chartOptions.series"
              [chart]="chartOptions.chart"
              [title]="chartOptions.title"
            ></apx-chart>
          </div>
    </div>
</div>