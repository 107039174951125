<app-nav-header></app-nav-header>

<section class="body">
    <div class="container-fluid">

        <app-side-nav></app-side-nav>

        <app-loader></app-loader>
        <div class="main">


                <section class="mini__header">
                    <div class="container-fluid">

                        <div class="mini__headerBody">




                                <div class="nav nav-tabs" id="nav-tab" role="tablist">

                                    <ul  class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                          <a class="nav-link" id="jobs-tab" data-bs-toggle="pill" data-bs-target="#jobs" role="tab" aria-controls="jobs" aria-selected="false" routerLink="/hr-jobs">Jobs</a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                          <a class="nav-link active" id="candidates-tab" data-bs-toggle="pill" data-bs-target="#candidates" role="tab" aria-controls="candidates" aria-selected="true" routerLink="/candidates">Candidates</a>
                                        </li>
                                        <!-- <li class="nav-item" role="presentation">
                                          <a class="" id="talent-tab" data-bs-toggle="pill" data-bs-target="#talent"  role="tab" aria-controls="talent" aria-selected="false">Talent Pool</a>
                                        </li> -->
                                      </ul>

                                </div>

                        </div>

                    </div>
                </section>

                <section>
                    <div class="container-fluid candidate-table">
                        <div class="row">
                          <div class="col-md-6"><h6><b>Candidates: {{candidates?.length}}</b></h6></div>
                          <div class="col-md-6 text-end">
                            <h6><div class="dropdown">
                              Records:

                              <button class="dropDown dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                25
                              </button>
                              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a class="dropdown-item" >10</a></li>
                                <li><a class="dropdown-item" >15</a></li>
                                <li><a class="dropdown-item" >5</a></li>
                              </ul>
                            </div>
                          </h6>
                        </div>
                            <div class="col-md-12">
                                <div class="card">

                                    <div class="card-body">

                                          <div class="tab-content" id="nav-tabContent">
                                            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                <table class="table">
                                                    <thead>
                                                      <tr>
                                                        <th scope="col">NO.</th>
                                                        <th scope="col">NAME</th>
                                                        <th scope="col">JOB</th>
                                                        <th scope="col">DEPARTMENT</th>
                                                        <th scope="col">EMAIL</th>
                                                        <th scope="col">PHONE NUMBER</th>
                                                        <th scope="col">APPLICATION SCORE MATCH</th>
                                                        <th scope="col">PROFILE</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr *ngFor='let x of candidates; index as i'>
                                                        <td>{{i + 1}}</td>
                                                        <td>{{x.first_name}} {{x.last_name}}</td>
                                                        <td>{{x.job?.title}}</td>
                                                        <td>{{x.job?.department?.name | titlecase}}</td>
                                                        <td>{{x.email}}</td>
                                                        <td>{{x.phone_number}}</td>
                                                        <td>{{x.skills_points_percentage}} %</td>
                                                        <td><i class="far fa-eye" (click)="viewProfile(x.id)"></i></td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                            </div>
                                          </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


        </div>
    </div>

</section>
