import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpConfigInterceptor implements HttpInterceptor {

  apiUrl = environment.apiURL;

  constructor(    private toastrService: ToastrService,) { }



  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token: string = localStorage.getItem('currentUserToken');


    if (request.url.startsWith(this.apiUrl)) {
      return next.handle(request);
    } else {

      if (token) {
        request = request.clone({ headers: request.headers.set('Authorization', `Token ${token}`) });
      }
      // if (!request.headers.has('Content-Type')) {
        //     request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        // }
        //
        // request = request.clone({ headers: request.headers.set('Accept', 'application/json') });


        return next.handle(request).pipe(
          map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              // console.log('event--->>>', event);
            }
            return event;
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 500) {
              this.toastrService.warning('Error', 'Oops!!! something went wrong. Try refreshing the page or contact us if the problem persists');
            }
            if (error.status === 403) {
              this.toastrService.warning('Error', `Oops!!! You don't have the permissions to view this page. Please contact the Admin for help`);
            }
            if (error.error.errors !== undefined) {
              const errorObject = error.error.errors;
              for (const [key, value] of Object.entries(errorObject)) {
                const errorValues = value;
                if (typeof errorValues === 'object') {
                  for (const [item, pair] of Object.entries(errorValues)) {

                    this.toastrService.warning('Error', key + ' - ' + value);
                  }
                } else {
                  this.toastrService.warning('Error', key + ' - ' + value);
                }
              }
            }
            return throwError(error);
          }),
        );
    }


  }

}
