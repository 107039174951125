import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DashService } from 'src/app/shared/services/dash.service';

@Component({
  selector: 'app-candidates',
  templateUrl: './candidates.component.html',
  styleUrls: ['./candidates.component.css']
})
export class CandidatesComponent implements OnInit {

  candidates = [];
  jobId: any;

  constructor(
    private dashService: DashService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }



  ngOnInit(): void {
    this.jobId = +this.route.snapshot.paramMap.get('id');
    if (this.jobId === 0) {
      this.fetchCandidates();
    } else {
      this.viewCandidates(this.jobId);
    }
  }

  fetchCandidates() {
    this.dashService.fetchCandidates()
    .subscribe(data => {
      this.candidates = data.results;
      // console.log(data);
    })
  }

  viewCandidates(id: any) {
    this.dashService.loadAllCandidatePerJob(id)
    .subscribe(data => {
      this.candidates = data;
      // console.log(data);
    })
  }

  viewProfile(id: any) {
    this.router.navigate([`/candidate-profile/${id}`])
  }
}
