<app-navbar></app-navbar>

<!-- <div class="container-fluid">
  <div class="row">
    <img src="https://adriankenya.com/new/wp-content/uploads/2021/08/Careers-in-Adrian-Kenya-available-1.jpg" alt="Adrian">
    <div class="overlay">
      <h1 class="heading">Join <br><span class="sub-heading">Adrian</span></h1>
    </div>
  </div>
  <div class="row blue-line"></div>
</div> -->

<!-- <section class="content-section">
  <div class="container">
    <div class="row mb-4">
      <div class="col-md-10 offset-md-1 text-center">
        <h1 class="content-title">
          Come help us create great engineering solutions
        </h1>
        <p class="content-text">
          At Adrian, we enable leading companies to meet their innovation needs by providing technology and engineering solutions. We’ve built a company we truly love working for, and we think you will too.
        </p>
      </div>
    </div>
    <br>
    <div class="row mt-4">
      <div class="col-md-6 text-left">
        <fa-icon [icon]="faSmile"></fa-icon>
        <h5>Enjoy Meaningful Work</h5>
        <p>Come prepared to do meaningful work that impacts the infrastructure, engineering and technology industries in Africa. As we strive for excellence, we learn from each other and our experiences.</p>
      </div>
      <div class="col-md-6 text-left">
        <fa-icon [icon]="faLayerGroup"></fa-icon>
        <h5>Learning and Development</h5>
        <p>When you grow, so do we. That’s why we’ve invested in an online platform where our employees can get trained and grow their skills.</p>
      </div>
      <div class="col-md-6 text-left">
        <fa-icon [icon]="faUsers"></fa-icon>
        <h5>We’ve Got You Covered</h5>
        <p>We take care of our employees in more ways than one. Our competitive benefits package includes medical, dental, and optical coverage for employees and their dependents.</p>
      </div>
      <div class="col-md-6 text-left">
        <fa-icon [icon]="faComments"></fa-icon>
        <h5>We Give Back</h5>
        <p>We’re a company committed to communities around us. It’s important for us that the communities we work in and with are confident that they are engaging with a company that cares about their future.</p>
      </div>
    </div>
  </div>
</section> -->

<br>
<br>
<br>
<section>
  <div class="container mt-4">
    <div class="row">
      <div class="col-md-12 text-center">
        <h1 class="content-title">
          Open Positions
        </h1>
      </div>

      <div class="col-md-10 offset-md-1">
        <p class="content-text">
          We ❤️ our Pan African culture at Adrian and we currently have hiring entities in Kenya and South Africa. Anyone willing to relocate to any of these countries is eligible to work with us.
        </p>
      </div>

      <div class="col-md-10 offset-md-1">
        <app-loader></app-loader>
        <ul class="job-list" *ngFor="let job of jobs">
          <li class="job-preview">
            <div class="content float-left">
              <h4 class="job-title">
                {{job?.title}}
              </h4>
              <h5 class="company">
                {{job?.category?.name}}
              </h5>
            </div>
              <a routerLink="/application/{{job?.id}}" class="btn btn-apply float-sm-right float-xs-left"> Apply</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
