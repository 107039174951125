<section class="header">
    <div class="nav">

      <div class="header__navItems">
        <div class="header__navItemsImage">
          <img src="/assets/images/AAAL.png" alt="">
        </div>
        <div class="header__nav">
          <h5>Recruiting</h5>
          <a>Career Profile</a>
        </div>
      </div>

      <div class="header__navCenter" [hidden]="!logginStatus">
        <a routerLink="/jobs"> Jobs </a>
        <a routerLink="/profile" [hidden]="!isCandidate">My Profile</a>
        <a routerLink="/home" [hidden]="!isCandidate">My Applications</a>
        <!-- <a routerLink="/faq"> FAQ </a> -->
        <!-- <a routerLink="/hr-jobs" [hidden]="isCandidate">HR Center</a> -->
      </div>


      <div class="header__navEnd" [hidden]="!logginStatus">
        <span class="span">{{userAvatar}}</span>
        <div class="dropdown" id="dropdownMenuButton" data-toggle="dropdown">
          <p>{{loggedInApplicant?.user?.first_name}}</p>
          <i class="fas fa-caret-down"></i>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <li>
            <a class="" (click)="logout()">Logout</a>
          </li>
        </div>
        </div>
      </div>

      <div class="header__navEnd" [hidden]="logginStatus">
        <i class="fas fa-user-circle"></i>
        <div>
          <a routerLink="/login">Login</a>
        </div>
      </div>

    </div>
  </section>
