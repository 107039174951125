import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DashService } from '../../shared/services/dash.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Job } from 'src/app/shared/models/job';
import { of } from 'rxjs';

@Component({
  selector: 'app-jobs-posting',
  templateUrl: './create-job.component.html',
  styleUrls: ['./create-job.component.css'],
})
export class JobsPostingComponent implements OnInit {
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '5rem',
    placeholder: 'Description here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [['bold']],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  form: FormGroup;
  submitted = false;
  submitSuccess = false;
  submitFailure = false;
  publishSuccess = false;
  companies: any = [];
  divisions: any = [];
  departments: any = [];
  job_types: any = [
    'Full-time onsite',
    'Full-time remote',
    'Temporary contract',
  ];
  locations: any = [];
  branches: any = [];
  skills: any = [];
  experiences: any = [];
  statuses: any = ['OPEN', 'CLOSED'];
  audiences: any = ['EXTERNAL', 'INTERNAL'];

  errorMessage: any;

  newJob: any;

  jobId: any;

  constructor(
    private formBuilder: FormBuilder,
    private dashService: DashService,
    private router: Router,
    private toastrService: ToastrService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.dashService.refresh$.subscribe(() => {
      this.skill();
      this.experience();
    });

    this.form = this.formBuilder.group({
      title: ['', Validators.required],
      company: ['', Validators.required],
      division: ['', Validators.required],
      department: ['', Validators.required],
      job_type: ['', Validators.required],
      location: ['', Validators.required],
      vacancies: ['', Validators.required],
      job_status: ['', Validators.required],
      description: ['', Validators.required],
      skills: ['', Validators.required],
      experience: ['', Validators.required],
      audience: ['', Validators.required],
      apply_by: ['', Validators.required],
      // is_published: ['', Validators.required],
    });

    this.company();
    this.division();
    this.department();
    this.skill();
    this.experience();
    this.location();

    this.jobId = +this.route.snapshot.paramMap.get('id');
    this.getJob(this.jobId);
  }

  getJob(id: number): void {
    this.dashService.loadJobById(id).subscribe((data) => {
      this.changeJob(data);
    });
  }

  company() {
    this.dashService.fetchCompany().subscribe((data) => {
      this.companies = data;
    });
  }

  division() {
    this.dashService.fetchDivision().subscribe((data) => {
      this.divisions = data;
    });
  }
  department() {
    this.dashService.fetchDepartment().subscribe((data) => {
      this.departments = data;
    });
  }
  location() {
    this.dashService.jobLocation().subscribe((data) => {
      this.locations = data;
    });
  }

  skill() {
    this.dashService.jobSkill().subscribe((data) => {
      this.skills = data.reverse();
    });
  }
  experience() {
    this.dashService.jobExperience().subscribe((data) => {
      this.experiences = data.reverse();
    });
  }

  submit(): void {
    this.submitted = true;

    const formData = {};
    formData['title'] = this.form.get('title').value;
    formData['company'] = this.form.get('company').value;
    formData['division'] = this.form.get('division').value;
    formData['department'] = this.form.get('department').value;
    formData['job_type'] = this.form.get('job_type').value;
    formData['location'] = this.form.get('location').value;
    formData['description'] = this.form.get('description').value;
    formData['skills'] = this.form.get('skills').value;
    formData['experience'] = this.form.get('experience').value;
    formData['job_status'] = this.form.get('job_status').value;
    formData['audience'] = this.form.get('audience').value;
    formData['vacancies'] = this.form.get('vacancies').value;
    formData['apply_by'] = this.form.get('apply_by').value;
    formData['is_published'] = 'true';

    if (this.jobId === 0 || this.newJob === 0) {
      this.dashService.jobCreate(formData).subscribe(
        (data) => {
          this.newJob = data;
          this.toastrService.success(
            'Success',
            'Job created successfully. Click publish to continue.'
          );
        },
        (error: HttpErrorResponse) => {
          if (error.error !== undefined) {
            const errorObject = error.error;

            for (const [key, value] of Object.entries(errorObject)) {
              this.errorMessage = key + ' - ' + value;
            }
          }
          this.toastrService.warning('Error', this.errorMessage);
        }
      );
    } else {
      this.dashService
        .updateJob(this.jobId, { ...formData, id: this.jobId })
        .subscribe(
          (data) => {
            this.newJob = data;
            this.toastrService.success('Success', 'Job editted successfully');
            this.form.reset();
          },
          (error: HttpErrorResponse) => {
            if (error.error !== undefined) {
              const errorObject = error.error;

              for (const [key, value] of Object.entries(errorObject)) {
                this.errorMessage = key + ' - ' + value;
              }
            }
            this.toastrService.warning('Error', this.errorMessage);
          }
        );
    }
  }

  publish() {
    if (this.newJob === null || this.newJob === undefined) {
      this.toastrService.info('Save job in order to view draft');
    } else {
      this.toastrService.success('Thank you', 'Job published successfully');
      setTimeout(() => {
        this.router.navigate([`/hr-jobs`]);
      }, 1000);
    }
  }

  cancel() {
    this.form.reset({
      division: '',
      department: '',
      company: '',
      location: '',
      job_type: '',
      job_status: '',
    });
  }

  saveSkills(skillForm) {
    const payload = {
      name: skillForm.name,
    };

    const modalCloseBtn = document.getElementById('close-skill');

    this.dashService.createSkill(payload).subscribe(
      () => {
        this.toastrService.success('Thank you', 'Skill submitted successfully');
        skillForm.reset();
        modalCloseBtn.click();
      },
      (error: HttpErrorResponse) => {
        if (error.error !== undefined) {
          const errorObject = error.error;

          for (const [key, value] of Object.entries(errorObject)) {
            this.errorMessage = key + ' - ' + value;
          }
        }
        this.toastrService.warning('Error', this.errorMessage);
      }
    );
  }

  saveExperience(experienceForm) {
    const payload = {
      name: experienceForm.name,
    };

    const modalCloseBtn = document.getElementById('close-exp');

    this.dashService.createExperience(payload).subscribe(
      () => {
        this.toastrService.success(
          'Thank you',
          'Experience submitted successfully'
        );
        experienceForm.reset();
        modalCloseBtn.click();
      },
      (error: HttpErrorResponse) => {
        if (error.error !== undefined) {
          const errorObject = error.error;

          for (const [key, value] of Object.entries(errorObject)) {
            this.errorMessage = key + ' - ' + value;
          }
        }
        this.toastrService.warning('Error', this.errorMessage);
      }
    );
  }

  changeJob(job: Job) {
    this.form.patchValue({
      title: job.title,
      division: job.division,
      department: job.department,
      job_type: job.job_type,
      location: job.location,
      company: job.company,
      description: job.description,
      skills: job.skills,
      experience: job.experience,
      job_status: job.job_status,
      apply_by: job.apply_by,
      vacancies: job.vacancies,
      audience: job.audience,
      is_published: job.is_published,
    });
  }

  //comparing between the default state and the state coming from the server
  comparingFn(c1, c2) {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }
}
