<section class="header">
    <div class="nav">

      <div class="header__nav">
        <a (click)="navigateToErp()">
          <img src="assets/images/adrianlogo-corporate.png" alt="Adrian Logo">
        </a>
      </div>

      <div class="header__navCenter">
        <h5>Human Resource Talent Center</h5>
      </div>

      <div class="header__navEnd">
        <div class="header__navEndMessage">
            <!-- <form class="header__input" action="/">
                <i class="fas fa-search"></i>
                <input type="search" placeholder="Search" />
            </form> -->
            <div class="header__inputMessage">
                <i class="far fa-envelope"></i>
                <i class="far fa-bell"></i>
                <span></span>
            </div>
        </div>

        <div class="header__navEndUI" *ngIf="logginStatus">
          <span class="span">{{userAvatar}}</span>
          <div class="dropdown" id="dropdownMenuButton" data-toggle="dropdown" >
            <p>{{decodedToken?.username}}</p>
            <i class="fas fa-caret-down"></i>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li>
                <a class="" (click)="logout()">Logout</a>
              </li>
            </div>
          </div>
        </div>


        <div class="header__navEndUI" *ngIf="!logginStatus">
          <i class="fas fa-user-circle"></i>
          <div>
            <a (click)="navigateToErp()">Login</a>
          </div>
        </div>

      </div>

    </div>
  </section>
