import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  loggedInApplicant = JSON.parse(localStorage.getItem('loggedInApplicant'));
  logginStatus: Boolean;
  userAvatar: any;
  isCandidate: Boolean;


  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.updateNavbar();
  }

  updateNavbar() {
    if (this.loggedInApplicant === null) {
      this.logginStatus = false;
      this.userAvatar = '';
    } else {
      this.userAvatar = this.loggedInApplicant.user.first_name.charAt(0);
      this.logginStatus = true;
      this.isCandidate = this.loggedInApplicant.user.is_candidate;
    }
  }


  logout() {
    this.authService.logout();
  }

}
