import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import jwt_decode from 'jwt-decode';
import { env } from '../../../../secret_env';

@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.css']
})
export class NavHeaderComponent implements OnInit {

  userToken = localStorage.getItem('currentUserToken');

  logginStatus: Boolean;
  userAvatar: any;

  decodedToken: any;



  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.decodedToken = jwt_decode(this.userToken);
    this.updateNavbar();
  }


  updateNavbar() {
    if (this.userToken === null) {
      this.logginStatus = false;
      this.userAvatar = '';
    } else {
      this.userAvatar = this.decodedToken.username.charAt(0);
      this.logginStatus = true;
    }
  }


  logout() {
    this.authService.hrLogout();
  }

  navigateToErp() {
    window.location.href = `${env.env.ARP_URL}`;
  }

}
