import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeWhile } from 'rxjs/operators';
import { AuthService } from '../shared/services/auth.service';
import { DashService } from '../shared/services/dash.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  alive = true;
  loggedInApplicant = JSON.parse(localStorage.getItem('loggedInApplicant'))


  logginStatus: Boolean;
  jobId: number;
  jobDetails: any;
  applicationStatus: any;


  constructor(
    private authService: AuthService,
    private dashService: DashService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.logginStatus = this.authService.isLoggedIn;
    this.getJob();
  }

  getJob() {
    this.jobId = +this.route.snapshot.paramMap.get('id');
    if (this.jobId === 0) {
      this.applicationStatus = 'You have not applied for any Jobs yet';
    } else {
      this.dashService.loadJobById(this.jobId)
      .pipe(takeWhile(() => this.alive))
      .subscribe(data => {
        this.jobDetails = data;
      });
    }
  }
}
