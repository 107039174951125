import { Component, OnInit } from '@angular/core';
import { DashService } from '../../shared/services/dash.service';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.css']
})
export class ApplicationsListComponent implements OnInit {

  jobs = [];

  numberOfJobs: number;

  faTrash = faTrash;
  faEdit = faEdit;

  errorMessage: string;

  constructor(
    private dashService: DashService,
    private router: Router,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.dashService.refresh$.subscribe(
      () => {
        this.getjobs();
      },
    );
    this.getjobs();
  }


  getjobs() {
    this.dashService.loadJobs()
      .subscribe(data => {
        this.jobs = data;
        this.numberOfJobs = this.jobs.length;
      }
    )
  }

  editJob(id: number) {
    this.router.navigate([`create-job/${id}`]);
  }


  removeJob(id: number) {
    if (window.confirm('Are you sure you want to delete this job?')) {
      this.dashService.deleteJob(id)
      .subscribe(
        () => {
          this.toastrService.success('Success', 'Job Deleted successfully');
        },
        (error: HttpErrorResponse) => {
          if (error.error !== undefined) {
            const errorObject = error.error;

            for (const [key, value] of Object.entries(errorObject)) {
              this.errorMessage = key + ' - ' + value;
            }
          }
          this.toastrService.warning('Error', this.errorMessage);
        }
      );
    }

  }








}
