import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DashService } from 'src/app/shared/services/dash.service';

@Component({
  selector: 'app-candidate-profile',
  templateUrl: './candidate-profile.component.html',
  styleUrls: ['./candidate-profile.component.css']
})
export class CandidateProfileComponent implements OnInit {

  statuses: any = ['SUBMITTED', 'SCREENING', 'INTERVIEWS','PENDING', 'FAILED', 'PASSED', 'OFFER', 'HIRED'];
  id: number;
  applicantDetails: any;

  form: FormGroup;
  errorMessage: string;

  constructor(
    private dashService: DashService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.id = +this.route.snapshot.paramMap.get('id');
    this.viewCandidateProfile(this.id);

    this.form = this.formBuilder.group({
      recruiter: ['', Validators.required],
      interview_date: ['', Validators.required],
      interview_time: ['', Validators.required],
      recruiter_notes: ['', Validators.required],
      status: ['', Validators.required],
      candidate: ['', Validators.required],
    })
  }

  viewCandidateProfile(id: any) {
    this.dashService.loadCandidateDetails(id)
    .subscribe(data => {
      this.applicantDetails = data;
      // console.log(data);
    })
  }



  submit(): void {
    // this.submitted = true;

    const formData = {}


    formData['recruiter'] = this.form.get('recruiter').value;
    formData['interview_date'] = this.form.get('interview_date').value;
    formData['interview_time'] = this.form.get('interview_time').value;
    formData['recruiter_notes'] = this.form.get('recruiter_notes').value;
    formData['status'] = this.form.get('status').value;
    formData['candidate'] = this.id;


    // if (this.jobId === 0) {
      this.dashService.createInterview(formData)
      .subscribe(
        (data) => {
          this.toastrService.success('Success', 'Successfully set the interview information');
        },
        (error: HttpErrorResponse) => {
          if (error.error !== undefined) {
            const errorObject = error.error;

            for (const [key, value] of Object.entries(errorObject)) {
              this.errorMessage = key + ' - ' + value;
            }
          }
          this.toastrService.warning('Error', this.errorMessage);
        }
      );
    // } else {
    //   this.dashService.updateJob(this.jobId, formData)
    //     .subscribe(
    //       (data) => {
    //         this.newJob = data;
    //         this.toastrService.success('Success', 'Job editted successfully');
    //       },
    //       (error: HttpErrorResponse) => {
    //         if (error.error !== undefined) {
    //           const errorObject = error.error;
    //
    //           for (const [key, value] of Object.entries(errorObject)) {
    //             this.errorMessage = key + ' - ' + value;
    //           }
    //         }
    //         this.toastrService.warning('Error', this.errorMessage);
    //       }
    //     );
    // }

  }












}
