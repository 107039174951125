import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { AppService } from '../shared/services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DashService } from '../shared/services/dash.service';
import { takeWhile } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-application-page',
  templateUrl: './application-page.component.html',
  styleUrls: ['./application-page.component.css'],
})
export class ApplicationPageComponent implements OnInit {
  alive = true;

  loggedInApplicant = JSON.parse(localStorage.getItem('loggedInApplicant'));
  applicantDetails: any;
  jobDetails: any;
  jobId: any;

  applicationForm: FormGroup;
  employers: FormArray;
  educations: FormArray;

  submitted = false;

  languages: any = [];
  jobs: any = [];

  coverFile: File = null;
  resumeFile: File = null;

  public show = true;
  public buttonName: any = 'Show Application Form';
  submitSuccess = false;
  submitFailure = false;
  errorMessage: string;

  employerIds = [];
  educationIds = [];

  applicationDetail: any;

  constructor(
    private formBuilder: FormBuilder,
    private appService: AppService,
    private router: Router,
    private dashService: DashService,
    private route: ActivatedRoute,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.applicationForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: [null, [Validators.required, Validators.email]],
      location: [''],
      phone_number: ['', Validators.required],
      linkedIn_URL: [''],
      gitHub_URL: [''],
      personal_website_URL: [''],
      resume: ['', Validators.required],
      cover_letter: [''],
      employers: this.formBuilder.array([this.jobForm()]),
      educations: this.formBuilder.array([this.eduForm()]),
    });

    this.getJob();
  }

  // validation getters starts
  get first_name() {
    return this.applicationForm.get('first_name');
  }

  get last_name() {
    return this.applicationForm.get('last_name');
  }

  get email() {
    return this.applicationForm.get('email');
  }
  get resume() {
    return this.applicationForm.get('resume');
  }
  get phone_number() {
    return this.applicationForm.get('phone_number');
  }

  // phone number input to allow only numbers
  keyPressNumbers(event) {
    let charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  eduForm(): FormGroup {
    return this.formBuilder.group({
      degree: ['', Validators.required],
      field_of_study: '',
      institution: '',
      start_date: '',
      end_date: '',
    });
  }

  jobForm(): FormGroup {
    return  this.formBuilder.group({
      job_title: '',
      company: '',
      start_date: '',
      end_date: '',
      summary: '',
  })
}

  getJob() {
    this.jobId = +this.route.snapshot.paramMap.get('id');
    this.dashService.loadJobById(this.jobId)
      .pipe(takeWhile(() => this.alive))
      .subscribe(data => {
        this.jobDetails = data;
      });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'});
  }

  toggle() {
    this.show = !this.show;

    // CHANGE THE NAME OF THE BUTTON.
    if(this.show)
      this.buttonName = "Hide Application Form";
    else
      this.buttonName = "Show Application Form";
  }



  addJob(): void {
    this.employers = this.applicationForm.get('employers') as FormArray;
    this.employers.push(this.jobForm());
  }

  deleteJob(index: number) {
    this.employers = this.applicationForm.get('employers') as FormArray;
    this.employers.removeAt(index)
  }

  addEdu(): void {
    this.educations = this.applicationForm.get('educations') as FormArray;
    this.educations.push(this.eduForm());
  }

  deleteEdu(index: number) {
    this.educations = this.applicationForm.get('educations') as FormArray;
    this.educations.removeAt(index)
  }

  uploadCoveletter(event: any) {
    this.coverFile = event.target.files[0] as File;
  }

  uploadResume(event: any) {
    this.resumeFile = event.target.files[0] as File;

  }

  //employment history
  saveEmploymentHistory(): void {

    const jobData = this.applicationForm.value.employers;
    const formData = new FormData;

    for(let i = 0;  i <= jobData.length - 1; i++) {
      formData.append('job_title', jobData[i].job_title);
      formData.append('company', jobData[i].company);
      formData.append('start_date', jobData[i].start_date);
      formData.append('end_date', jobData[i].end_date);
      formData.append('summary', jobData[i].summary);

      this.appService.postAppEmployer(formData)
      .subscribe(
        data => {
          this.employerIds.push(data.id);
        },
        (error: HttpErrorResponse) => {
          if (error.error !== undefined) {
            const errorObject = error.error;

            for (const [key, value] of Object.entries(errorObject)) {
              this.errorMessage = key + ' - ' + value;
            }
          }
          this.toastrService.warning('Error', this.errorMessage);
        }
      );
    }
  }



  saveEdu(): void {

    const eduData = this.applicationForm.value.educations;
    const formData = new FormData;

    for (let i = 0; i <= eduData.length - 1; i++) {
      formData.append('Degree', eduData[i].degree);
      formData.append('field_of_study', eduData[i].field_of_study);
      formData.append('start_date', eduData[i].start_date);
      formData.append('end_date', eduData[i].end_date);
      formData.append('institution', eduData[i].institution);

      this.appService.postAppEducation(formData)
      .subscribe(
        data => {
          this.educationIds.push(data.id);
        },
        (error: HttpErrorResponse) => {
          if (error.error !== undefined) {
            const errorObject = error.error;

            for (const [key, value] of Object.entries(errorObject)) {
              this.errorMessage = key + ' - ' + value;
            }
          }
          this.toastrService.warning('Error', this.errorMessage);
        }
      );
    }
  }


  submit(): void {

    this.submitted = true;

    if(this.employerIds.length>0) {
      this.saveEmploymentHistory();
    }

    if (this.educationIds.length>0) {
      this.saveEdu();
    }

    const formData = new FormData;

    setTimeout(() => {
      formData.append('first_name', this.applicationForm.get('first_name').value);
      formData.append('last_name', this.applicationForm.get('last_name').value);
      formData.append('email', this.applicationForm.get('email').value);
      formData.append('+' + 'phone_number', this.applicationForm.get('phone_number').value);
      formData.append('resume', this.resumeFile, this.resumeFile.name);
      if(this.coverFile){
        formData.append('cover_letter', this.coverFile, this.coverFile?.name);
      }
      formData.append('location', this.applicationForm.get('location').value);
      formData.append('linkedIn_URL', this.applicationForm.get('linkedIn_URL').value);
      formData.append('personal_website_URL', this.applicationForm.get('personal_website_URL').value);
      formData.append('gitHub_URL', this.applicationForm.get('gitHub_URL').value);
      formData.append('job', this.jobId);

      for(let i = 0; i < this.employerIds.length; i++) {
        formData.append('employer',  JSON.stringify(this.employerIds[i]));
      }

      for (let i = 0; i < this.educationIds.length; i++) {
        formData.append('education', JSON.stringify(this.educationIds[i]));
      }


      this.appService.application(formData)
      .subscribe(
        (data) => {
          this.applicationDetail = data;
          this.toastrService.success('Thank you', 'Application submitted successfully');

          setTimeout(() => {
            this.toastrService.info('Info', 'Login to view your application status');
          }, 3000);
        },
        (error: HttpErrorResponse) => {
          this.submitted = false;

          if (error.error !== undefined) {
            const errorObject = error.error;

            for (const [key, value] of Object.entries(errorObject)) {
              this.errorMessage = key + ' - ' + value;
            }
          }
          this.toastrService.warning('Error', this.errorMessage);
        }
      )}, 3000)
  }
}

