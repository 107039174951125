<app-nav-header></app-nav-header>

<section class="body">
    <div class="container-fluid">

        <app-side-nav></app-side-nav>

        <app-loader></app-loader>


        <div class="main">



            <section class="mini__header jobs__SH">
                <div class="container-fluid">

                    <div class="mini__headerBody">

                        <div class="nav nav-tabs" id="nav-tab" role="tablist">

                            <ul  class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link active" id="dashboard-tab" data-bs-toggle="pill" data-bs-target="#dashboard" role="tab" aria-controls="dashboard" aria-selected="true">Dashboard</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" id="employees-tab" data-bs-toggle="pill" data-bs-target="#employees" role="tab" aria-controls="employees" aria-selected="false">Employees</a>
                                </li>
                                </ul>

                        </div>
                    </div>

                </div>
            </section>


              <div style="margin-top: 10vh;" class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="row">
                                    <div class="col-md-12 mt-1">
                                        <div class="card">
                                            <div class="card-header ch">
                                                <div class="row">
                                                    <div class="col-md-6 interview">
                                                        <h6>Interviews</h6>
                                                    </div>
                                                    <div class="col-md-6">
                                                            <div class="dashboard__content">
                                                                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                                    <a id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home">Today</a>
                                                                    <a id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile">Upcoming</a>
                                                                    <a id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact">Completed</a>
                                                                  </div>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-body">

                                                  <div class="tab-content" id="nav-tabContent">
                                                    <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                        <table class="table">
                                                            <thead>
                                                              <tr>
                                                                <th scope="col">No.</th>
                                                                <th scope="col">Candidate</th>
                                                                <th scope="col">Interview Date</th>
                                                                <th scope="col">Interview Time</th>
                                                                <th scope="col">Recruiter</th>
                                                                <th scope="col">Recruiter Notes</th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              <tr *ngFor="let int of interviews; index as i">
                                                                <td>{{i + 1}}</td>
                                                                <td>{{int.candidate}}</td>
                                                                <td>{{int.interview_date}}</td>
                                                                <td>{{int.interview_time}}</td>
                                                                <td>{{int.recruiter}}</td>
                                                                <td>{{int.recruiter_notes}}</td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                    </div>
                                                    <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                                        <table class="table">
                                                            <thead>
                                                              <tr>
                                                                <th scope="col">No.</th>
                                                                <th scope="col">Candidate</th>
                                                                <th scope="col">Job</th>
                                                                <th scope="col">Job Status</th>
                                                                <th scope="col">Last Updated</th>
                                                                <th scope="col">Department</th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                    </div>
                                                    <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                                        <table class="table">
                                                            <thead>
                                                              <tr>
                                                                <th scope="col">Candidate</th>
                                                                <th scope="col">Job</th>
                                                                <th scope="col">Job Status</th>
                                                                <th scope="col">Last Updated</th>
                                                                <th scope="col">Recruiter</th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                    </div>
                                                  </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-4">
                                        <div class="card">
                                            <div class="card-header ch">
                                                <div class="row">
                                                    <div class="col-md-6 interview">
                                                        <h6>New Candidates - Last 7 days</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <table class="table">
                                                    <thead>
                                                      <tr>
                                                        <th scope="col">No.</th>
                                                        <th scope="col">Candidate</th>
                                                        <th scope="col">Job</th>
                                                        <th scope="col">Department</th>
                                                        <th scope="col">Job Status</th>
                                                        <th scope="col">Last Updated</th>
                                                        <!-- <th scope="col">Recruiter</th> -->
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr *ngFor="let cand of candidates; index as i">
                                                        <td>{{i + 1}}</td>
                                                        <td>{{cand.first_name | titlecase}} {{cand.last_name | titlecase}} </td>
                                                        <td>{{cand.job?.title}}</td>
                                                        <td>{{cand.job?.department?.name | titlecase}}</td>
                                                        <td>{{cand.status}}</td>
                                                        <td>{{cand.date_modified | date:'medium'}}</td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card cardMain">
                                    <div class="card-header">
                                        <h6>Highlights</h6>
                                    </div>
                                    <div class="inner__cards">
                                        <div class="card">
                                            <div class="card-header">
                                                <div class="row">
                                                    <div class="col-md-8">
                                                        <div class="card-headerLeft">
                                                            <i class="fas fa-bars"></i>
                                                            <p>Open Tasks</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="card-headerRight">
                                                            <p>10</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="cards">
                                                <div class="card-header copy">
                                                    <div class="row">
                                                        <div class="col-md-8">
                                                            <div class="card-headerLeft">
                                                                <i class="fas fa-user-friends"></i>
                                                                <p>Candidates summary</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-md-8">
                                                            <p>New Candidates</p>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <p>2</p>
                                                        </div>
                                                        <div class="col-md-8">
                                                            <p>Candidates Responses</p>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <p>1</p>
                                                        </div>
                                                        <div class="col-md-8">
                                                            <p>Waiting For My Action </p>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <p>0</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="cards">
                                                <div class="card-header copy">
                                                    <div class="row">
                                                        <div class="col-md-8">
                                                            <div class="card-headerLeft">
                                                                <i class="fas fa-tag"></i>
                                                                <p>Offer to hire highlights</p>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <div class="card-headerRight">

                                                                <div class="dropdown">
                                                                    <p class="drpdwn"  id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ><u>Last 30 Days</u></p>
                                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                      <a class="dropdown-item" >Today</a>
                                                                      <a class="dropdown-item" >Last 7 Days</a>
                                                                      <a class="dropdown-item" >Last 30 Days</a>
                                                                    </div>
                                                                  </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-md-8">
                                                            <p>Offers Made</p>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <p>0</p>
                                                        </div>
                                                        <div class="col-md-8">
                                                            <p>Offers Accepted</p>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <p>0</p>
                                                        </div>
                                                        <div class="col-md-8">
                                                            <p>Offers Declined </p>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <p>0</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="employees" role="tabpanel" aria-labelledby="employees-tab">
                    <div class="container-fluid employees">
                       <div class="row">

                           <div class="col-md-12">
                            <div class="card">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h4>HR Snapshot</h4>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-3 text-center">
                                                <p class="number">1</p>
                                                <p>Onboarding Review Pending</p>
                                            </div>
                                            <div class="col-md-3 text-center">
                                                <p class="number">0</p>
                                                <p>Additions</p>
                                            </div>
                                            <div class="col-md-3 text-center">
                                                <p class="number">2</p>
                                                <p>Terminations</p>
                                            </div>
                                            <div class="col-md-3 text-center end">
                                                <p class="number">3</p>
                                                <p>Not Logged-in</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           </div>

                           <div class="col-md-12 mt-4">
                                <div class="card">
                                    <app-hc-graph></app-hc-graph>
                                </div>
                           </div>

                           <div class="col-md-12 mt-4">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="card">
                                            <app-hcdpt-graph></app-hcdpt-graph>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="card">
                                            <app-td-graph></app-td-graph>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12 mt-4">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="card">
                                            <app-age-d-graph></app-age-d-graph>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="card">
                                            <app-gender-d-graph></app-gender-d-graph>
                                        </div>
                                    </div>
                                </div>
                            </div>

                       </div>
                    </div>
                </div>
              </div>


    </div>

    </div>

</section>
