<app-navbar></app-navbar>
<section id="hero">
    <div class="hero container">
        <div [hidden]="logginStatus" class="hero__center">
            <h4>Welcome !</h4>
        </div>
        <div [hidden]="!logginStatus" class="hero__center" >
          <h4>Welcome {{loggedInApplicant?.user?.first_name}}!</h4>
      </div>
    </div>
</section>

<section class="track__status">

  <div class="container-fluid">
    <h5> Track your status </h5>

    <div class="card">
      <div class="container-fluid">
        <h6><b>You're Interviewing for {{jobDetails?.title}}</b></h6>
        <p>Here's a preview of the interview process</p>

        <ul class="progressbar">
          <li class="active"> Open </li>
          <li> Screening </li>
          <li> Interviews </li>
          <li> Offer </li>
          <li> Hire </li>
        </ul>
      </div>
      <div class="container-fluid">
      </div>


    </div>
  </div>
</section>



<section class="updates">
  <div class="container-fluid">

    <div class="container-fluid">

      <h5> Updates</h5>

      <div class="card">
        <div class="container-fluid">
          <h6><b>{{applicationStatus}}</b></h6>
          <p>Update your profile information</p>
        </div>

      </div>
    </div>
  </div>
</section>

<!-- <section class="updates">
  <div class="container-fluid">

    <div class="container-fluid">

      <div class="updates__Title">
        <h5> Related blog posts</h5>
        <a href=""> Show more </a>
      </div>

      <div class="row">
        <div class="col-md-4">
          <div class="card">
        <div class="container-fluid">
          <p>Blog</p>
        </div>

          </div>
            </div>
            <div class="col-md-4">
              <div class="card">
            <div class="container-fluid">
              <p>Blog</p>
            </div>

          </div>
            </div>
            <div class="col-md-4">
              <div class="card">
            <div class="container-fluid">
              <p>Blog</p>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
