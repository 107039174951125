import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import jwt_decode from "jwt-decode";


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  userToken = localStorage.getItem('currentUserToken')? true : false;

  loggedInApplicantDecodedToken = jwt_decode(localStorage.getItem('currentUserToken'));

      // any user with a role_name is an employee and allowed to view jobs
  userHasRole = this.loggedInApplicantDecodedToken.hasOwnProperty("role_name");

      // lets get the name of the role, see if its HR or admin
  checkRoleName(decodedToken, roleName) {
    return Object.keys(decodedToken).find(key => decodedToken[key] === roleName);
  }

      // check user roles to allow limited access only
  isHR = !!this.checkRoleName(this.loggedInApplicantDecodedToken, "TECHNICIAN")

  constructor(
    private router: Router,
    private authService: AuthService,
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean> | Promise<boolean> | boolean {


    // user need not be logged in to see and apply for jobs, if unauthenticated, redirect to jobs page
    if (!this.userHasRole) {
      this.router.navigate(['/jobs']);
    }

    return this.userHasRole;
  }
}
