import { InterceptorService } from './shared/services/interceptor/interceptor.service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from "ng-apexcharts";


import { AppComponent } from './app.component';
import { HomepageComponent } from './candiate-homepage/homepage.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ProfileComponent } from './profile/profile.component';
import { FaqpageComponent } from './faqpage/faqpage.component';
import { ApplicationPageComponent } from './application-page/application-page.component';
import { PasswordResetComponent } from './login/password-reset/password-reset.component';


import { ToastrModule } from 'ngx-toastr';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngularMaterialModule } from './shared/modules/angular-material.module';

import { NgSelectModule } from '@ng-select/ng-select';
import { CookieService } from 'ngx-cookie-service';



import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpConfigInterceptor } from './shared/interceptors/httpconfig.interceptor';
import { AngularEditorModule } from '@kolkov/angular-editor';


import { HelloComponent } from './application-page/hello.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { JobListComponent } from './job-list/job-list.component';

import { AlertComponent } from '../app/components/alert.component';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { ApplicationsListComponent } from './dashboard-page/applications-list/jobs.component';
import { NavHeaderComponent } from './dashboard-page/nav-header/nav-header.component';
import { SideNavComponent } from './dashboard-page/side-nav/side-nav.component';
import { JobsPostingComponent } from './dashboard-page/create-job/create-job.component';
import { CandidatesComponent } from './dashboard-page/candidates/candidates.component';


import { HcGraphComponent } from './dashboard-page/hc-graph/hc-graph.component';
import { HcdptGraphComponent } from './dashboard-page/hcdpt-graph/hcdpt-graph.component';
import { TdGraphComponent } from './dashboard-page/td-graph/td-graph.component';
import { AgeDGraphComponent } from './dashboard-page/age-d-graph/age-d-graph.component';
import { GenderDGraphComponent } from './dashboard-page/gender-d-graph/gender-d-graph.component';
import { CandidateProfileComponent } from './dashboard-page/candidate-profile/candidate-profile.component';
import { LoaderComponent } from './shared/_components/loader/loader.component';




@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    LoginComponent,
    SignupComponent,
    ProfileComponent,
    FaqpageComponent,
    ApplicationPageComponent,
    PasswordResetComponent,
    HelloComponent,
    NavbarComponent,
    FooterComponent,
    JobListComponent,
    AlertComponent,
    DashboardPageComponent,
    ApplicationsListComponent,
    NavHeaderComponent,
    SideNavComponent,
    JobsPostingComponent,
    CandidatesComponent,
    HcGraphComponent,
    HcdptGraphComponent,
    TdGraphComponent,
    AgeDGraphComponent,
    GenderDGraphComponent,
    CandidateProfileComponent,
    LoaderComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgApexchartsModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule, // required animations module
    AngularEditorModule,
    ToastrModule.forRoot(),
    FontAwesomeModule,
    AngularMaterialModule,
    NgSelectModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    CookieService,
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
