import { Component, OnInit } from '@angular/core';
import { DashService } from '../shared/services/dash.service';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.css']
})
export class DashboardPageComponent implements OnInit {

  interviews = [];
  candidates = [];

  constructor(
    private dashService: DashService,
  ) { }

  ngOnInit(): void {
    this.fetchInterviews();
    this.fetchCandidates();
  }


  fetchInterviews() {
    this.dashService.fetchInterviews()
    .subscribe(data => {
      this.interviews = data;
    })
  }


  fetchCandidates() {
    this.dashService.fetchCandidates()
    .subscribe(data => {
      this.candidates = data.results;
    })
  }



}
