<app-navbar></app-navbar>
<br>
<br>
<br>
<br>
<br>
<br>

<div class="container">
  <div class="row">
    <div class="col-md-6 padding-0">
      <div class="card card__left">
        <div class="card__leftImage">
          <img src="assets/images/adrianlogo-cosmic.png" alt="Adrian Logo">
        </div>
        <div class="card__leftText">
          <h6>WELCOME TO THE</h6>
          <h3>ADRIAN <a> CAREER </a> PORTAL</h3>
        </div>
      </div>
    </div>
    <div class="col-md-6 padding-0">
      <div class="card">
        <div class="card__right">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <h5 class="text-center">Create an Account</h5>
              <form *ngIf="form" [formGroup]="form" (submit)="submit()">
                <div class="form-group">
                  <input formControlName="first_name" type="text" class="form-control" placeholder="First Name" [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }">
                  <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                    <div *ngIf="f.first_name.errors.required">First Name is required</div>
                  </div>
                </div>

                <div class="form-group">
                  <input formControlName="last_name" type="text" class="form-control" placeholder="Last Name" [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }">
                  <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                    <div *ngIf="f.last_name.errors.required">Last Name is required</div>
                  </div>
                </div>
                <div class="form-group">
                  <input formControlName="phone_number" type="tel" class="form-control" placeholder="Phone Number" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}">
                </div>
                <div class="form-group">
                  <input formControlName="email" type="email" class="form-control" placeholder="Email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                  </div>
                </div>
                <div class="form-group">
                  <input formControlName="password" type="password" class="form-control" placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Password required</div>
                  </div>
                </div>
                <div class="form-group">
                  <input formControlName="confirm_password" type="password" class="form-control" placeholder="Confirm Password" [ngClass]="{ 'is-invalid': submitted && f.confirm_password.errors }">
                  <div *ngIf="submitted && f.confirm_password.errors" class="invalid-feedback">
                    <div *ngIf="f.confirm_password.errors.required">Password confirmation is required</div>
                  </div>
                </div>
                <!-- <div class="form-check">
                  <input formControlName="acceptTerms" type="checkbox" class="form-check-input" id="acceptTerms">
                  <label class="form-check-label btnCfrm" for="acceptTerms">I have accepted the <a href="" >Terms of Service</a> </label>
                </div> -->
                <button type="submit" class="btnSignUp btn-block btn btn-primary">Register</button>
                <p class="text-center account btnCfrm"><a routerLink="/login">Already have an account? login </a> </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
