<app-navbar></app-navbar>

  <section id="hero">
      <div class="hero container">
          <div class="hero__center">
              <h4>? FAQ</h4>
          </div>
      </div>
  </section>

  <section class="profile">
      <div class="container">
          <div class="row">

              <div class="col-md-10">
                <div class="card">
                   <div class="faq">
                       <p>How long should I expext to wait for a decision?</p>
                       <p><i class="fas fa-caret-down"></i></p>
                   </div>
                </div>
            </div>

            <div class="col-md-10">
                <div class="card">
                   <div class="faq">
                       <p>How long should I expext to wait for a decision?</p>
                       <p><i class="fas fa-caret-down"></i></p>
                   </div>
                </div>
            </div>

            <div class="col-md-10">
                <div class="card">
                   <div class="faq">
                       <p>How long should I expext to wait for a decision?</p>
                       <p><i class="fas fa-caret-down"></i></p>
                   </div>
                </div>
            </div>

            <div class="col-md-10">
                <div class="card">
                   <div class="faq">
                       <p>How long should I expext to wait for a decision?</p>
                       <p><i class="fas fa-caret-down"></i></p>
                   </div>
                </div>
            </div>

            <div class="col-md-10">
                <div class="card">
                   <div class="faq">
                       <p>How long should I expext to wait for a decision?</p>
                       <p><i class="fas fa-caret-down"></i></p>
                   </div>
                </div>
            </div>





          </div>
      </div>
  </section>
