<app-navbar></app-navbar>

<section id="hero">
  <div class="hero container">
      <div [hidden]="logginStatus" class="hero__center">
          <h4>Welcome !</h4>
      </div>
      <div [hidden]="!logginStatus" class="hero__center" >
        <h4>Welcome {{loggedInApplicant?.user?.first_name}}!</h4>
    </div>
  </div>
</section>

  <section class="profile">
      <div class="container">
          <div class="row">
              <div class="col-md-10">
                  <div class="basic__title">
                    <i class="fas fa-file-alt"></i>
                    <h6> Basics </h6>
                  </div>
                  <div class="card">
                      <!-- <div class="row">
                        <div class="col-md-12">
                          <p class="edit">Edit</p>
                        </div>
                      </div> -->
                      <!-- <p class="edit">Edit</p> -->

                      <div class="row">
                        <div class="col-md-12 text-end">
                          <p class="cool" >Edit</p>
                        </div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="user__details">
                                  <p>Name:</p>
                                  <p class="user__detailsInfo" >{{loggedInApplicant?.user?.first_name}}</p>
                                </div>
                              </div>

                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="user__details">
                                  <p>Email:</p>
                                  <p class="user__detailsInfo" >{{loggedInApplicant?.user?.email}}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>

                      <div class="row">
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="user__details">
                                  <p>Phone Number:</p>
                                <p class="user__detailsInfo" >{{loggedInApplicant?.user?.phone_number}}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="user__details">
                                  <p>Linkedin URL:</p>
                                <p class="user__detailsInfo" >{{applicantDetails?.linkedIn_URL}}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="user__details">
                                <p>Github URL:</p>
                              <p class="user__detailsInfo" >{{applicantDetails?.gitHub_URL}}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="user__details">
                                <p>Personal Website:</p>
                              <p class="user__detailsInfo" >{{applicantDetails?.personal_website_URL}}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="user__details">
                                <p>Location:</p>
                                <p class="user__detailsInfo" >{{applicantDetails?.location}}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>

                  </div>
              </div>



            <div class="col-md-10">
                <div class="basic__title">
                    <i class="far fa-file"></i>
                  <h6> Resume </h6>
                </div>
                <div class="card">
                   <div class="password">
                       <p>{{applicantDetails?.resume}}</p>
                       <p class="cool">Edit</p>
                   </div>
                </div>
            </div>

            <div class="col-md-10">
                <div class="basic__title">
                  <i class="fas fa-pen-fancy"></i>
                  <h6> Skills </h6>
                </div>
                <div class="card">
                   <div class="password">
                       <p>{{applicantDetails?.skills}}</p>
                       <p class="cool">Edit</p>
                   </div>
                </div>
            </div>

            <div class="col-md-10">
                <div class="basic__title">
                  <i class="fas fa-comment-alt"></i>
                  <h6> Languages </h6>
                </div>
                <div class="card">
                   <div class="password">
                       <p>{{applicantDetails?.languages}}</p>
                       <p class="cool">Edit</p>
                   </div>
                </div>
            </div>


            <div class="col-md-10">
              <div class="basic__title">
                <i class="fas fa-key"></i>
                <h6> Password </h6>
              </div>
              <div class="card">
                 <div class="password">
                     <p>........</p>
                     <p class="cool">Change</p>
                 </div>
              </div>
          </div>


          </div>
      </div>
  </section>


  <!-- <div class="row form__files">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-2">
          <p>Resume/CV <sup>*</sup> </p>
        </div>
        <div class="col-md-10">
          <input type="file" class="form-control" (change)="uploadResume($event)"  />
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-2">
          <p>Cover Letter</p>
        </div>
        <div class="col-md-10">
          <input type="file" class="form-control" (change)="uploadCoveletter($event)"  />
        </div>
      </div>
    </div>
  </div> -->
