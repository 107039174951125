<app-navbar></app-navbar>
<br>
<br>
<br>
<br>
<br>
<br>
<div class="container">
  <div class="row">
    <div class="col-md-6 padding-0">
      <div class="card card__left">
        <div class="card__leftImage">
          <img src="assets/images/adrianlogo-cosmic.png" alt="Adrian Logo">
        </div>
        <div class="card__leftText">
          <h6>WELCOME BACK TO THE</h6>
          <h3>ADRIAN <a> CAREER </a> PORTAL</h3>
        </div>
      </div>
    </div>
    <div class="col-md-6 padding-0">
      <div class="card">
        <div class="card__right">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <h5 class="text-center">Log in to your Account</h5>
              <form [formGroup]="loginForm" (submit)="submit()">
                <div class="form-group">
                  <input formControlName="email" type="email" class="form-control inpBrd" placeholder="Email">
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                  </div>
                </div>
                <div class="form-group password">
                  <input  [type]="fieldTextType ? 'text' : 'password'" formControlName="password" type="password" class="form-control inpBrd" placeholder="Password">
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <i
                      class="far"
                      [ngClass]="{
                        'fa-eye-slash': !fieldTextType,
                        'fa-eye': fieldTextType
                      }"
                      (click)="toggleFieldTextType()"
                      ></i>
                    </span>
                  </div>
                  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Password required</div>
                  </div>
                </div>

                <p class="pass"><a routerLink="/password-reset">Forgot password? </a> </p>
                <button type="submit" class="btnSignIn btn-block btn btn-primary">LOG IN</button>

                <p class="text-center reg"><a routerLink="/signup"></a><a routerLink="/signup"> Don't have an account? register </a> </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
