import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Education } from '../models/job';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  apiUrl = environment.apiURL;

  constructor(private http: HttpClient) {}

  application(formData: any) {
    return this.http.post<any>(
      `${this.apiUrl}applications/applications/`,
      formData
    );
  }

  getApplication(id: any) {
    return this.http.get<any>(`${this.apiUrl}applications/applications/${id}`);
  }

  getAllApplications() {
    return this.http.get<any>(`${this.apiUrl}applications/applications/`);
  }

  appLanguage() {
    return this.http.get<any>(`${this.apiUrl}applications/languages/`);
  }

  appJobList() {
    return this.http.get<any>(`${this.apiUrl}jobs/jobs_list/`);
  }

  postAppEmployer(formData: any) {
    return this.http.post<any>(
      `${this.apiUrl}applications/employers/`,
      formData
    );
  }

  postAppEducation(formData: any):Observable<Education> {
    return this.http.post<any>(
      `${this.apiUrl}applications/education/`,
      formData
    );
  }

  appEmployer() {
    return this.http.get<any>(`${this.apiUrl}applications/employers/`);
  }

  appEmployerById(id: any) {
    return this.http.get<any>(`${this.apiUrl}applications/employers/${id}`);
  }

  appEducation() {
    return this.http.get<any>(`${this.apiUrl}applications/education/`);
  }

  appEducationById(id: any) {
    return this.http.get<any>(`${this.apiUrl}applications/education/${id}`);
  }

  getProfile(id: any) {
    return this.http.get<any>(`${this.apiUrl}profiles/profile/${id}`);
  }

  updateProfile(id: any, formdata) {
    return this.http.patch<any>(
      `${this.apiUrl}profiles/profile/${id}`,
      formdata
    );
  }

  loadCandidateProfile(id: any) {
    return this.http.get<any>(
      `${this.apiUrl}applications/profile/candidates/${id}`
    );
  }
}
