import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { AppService } from '../shared/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../shared/services/auth.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  alive = true;
  loggedInApplicant = JSON.parse(localStorage.getItem('loggedInApplicant'));
  logginStatus: Boolean;

  applicantDetails: any;

  coverFile: File = null;
  resumeFile: File = null;

  errorMessage: string;


  constructor(
    private appService: AppService,
    private toastrService: ToastrService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.logginStatus = this.authService.isLoggedIn;
    this.getUserDetails();
  }


  getUserDetails() {
    this.appService.getProfile(this.loggedInApplicant.user.id)
      .pipe(takeWhile(() => this.alive))
      .subscribe(data => {
        this.applicantDetails = data;
        // console.log(data);
        // console.log(this.loggedInApplicant);
      });

    }


    uploadCoveletter(event: any): void {
      this.coverFile = event.target.files[0] as File;
    }

    uploadResume(event: any) {
      this.resumeFile = event.target.files[0] as File;
    }



    submitCoverLetter(): void {
      const formData = new FormData;

      formData.append('cover_letter', this.coverFile, this.coverFile.name);


      this.appService.application(formData)
      .subscribe(
        () => {
          this.toastrService.success('Thank you', 'Updated successfully');
        },
        (error: HttpErrorResponse) => {
          if (error.error !== undefined) {
            const errorObject = error.error;

            for (const [key, value] of Object.entries(errorObject)) {
              this.errorMessage = key + ' - ' + value;
            }
          }
          this.toastrService.warning('Error', this.errorMessage);
        }
      )
    }


    submitResume(): void {
      const formData = new FormData;

      formData.append('resume', this.resumeFile, this.resumeFile.name);


      this.appService.application(formData)
      .subscribe(
        () => {
          this.toastrService.success('Thank you', 'Updated successfully');
        },
        (error: HttpErrorResponse) => {
          if (error.error !== undefined) {
            const errorObject = error.error;

            for (const [key, value] of Object.entries(errorObject)) {
              this.errorMessage = key + ' - ' + value;
            }
          }
          this.toastrService.warning('Error', this.errorMessage);
        }
      )
    }

}
