import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { env } from '../../../../secret_env';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiUrl = environment.apiURL;



  // private currentUserSubject: BehaviorSubject<User>;
  private loggedInApplicantSubject: BehaviorSubject<User>;
  public loggedInApplicant: Observable<User>;
  private loggedInStatus = JSON.parse(localStorage.getItem('loggedIn') || 'false');
  currentUserValue: any;

  constructor(private http: HttpClient, private router: Router, public cookieService: CookieService) {
    this.loggedInApplicantSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('loggedInApplicant')));
    this.loggedInApplicant = this.loggedInApplicantSubject.asObservable();
   }


  get loggedInApplicantValue(): User {
    return this.loggedInApplicantSubject.value;
  }

  signup(formData) {
    return this.http.post<any>(`${this.apiUrl}accounts/register/`, formData)
  }

  login(email: string, password: string) {
    return (
      this.http.post<any>(`${this.apiUrl}accounts/login/`, { email, password })
        .pipe(map(user => {
          if (user && user.token) {
            this.loggedInApplicantSubject.next(user);
          }
          return user;
        }))
    );
  }

  setLoggedIn(value: boolean) {
    this.loggedInStatus = value;
    localStorage.setItem('loggedIn', 'true');
  }
  get isLoggedIn() {
    return JSON.parse(localStorage.getItem('loggedIn') || this.loggedInStatus.toString());
  }
  logout() {
    localStorage.removeItem('loggedInApplicant');
    localStorage.removeItem('loggedIn');
    this.loggedInApplicantSubject.next(null);
    this.router.navigate(['/login'], {queryParams: {refresh: new Date().getTime()}});
  }

  hrLogout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentUserToken');
    localStorage.removeItem('loggedIn');
    this.cookieService.delete('user')
    this.cookieService.delete('currentUserToken')
    window.location.href = `${env.env.ARP_URL}`;

  }

}
