import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'adrian-recruitment-client';
  userToken: any;

  constructor( private router: Router, public cookieService: CookieService ){
    localStorage.setItem('currentUserToken', this.cookieService.get("user"));
    this.userToken = localStorage.getItem('currentUserToken');


    if (!this.userToken) {
      this.router.navigate(['jobs'])
    } else {
      this.router.navigate(['hr-page'])
    }

  }

}
