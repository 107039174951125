// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { env } from '../../secret_env';

export const environment = {
  production: false,
  apiURL: env.env.API_URL,
  GOOGLE_API_KEY: env.env.GOOGLE_API_KEY,
  GOOGLE_MAP_KEY: env.env.GOOGLE_MAP_KEY,
  arpApiUrl: env.env.ARP_API_URL,
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
