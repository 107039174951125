import { ErrorHandlerService } from './error-handler.service';
import { Job, Employment } from 'src/app/shared/models/job';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable, of, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DashService {
  apiUrl = environment.apiURL;
  arpApiUrl = environment.arpApiUrl;

  private refreshNeeded$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) {}

  get refresh$() {
    return this.refreshNeeded$;
  }

  createSkill(formData) {
    return this.http.post(`${this.apiUrl}jobs/skills/`, formData).pipe(
      tap(() => {
        this.refreshNeeded$.next();
      })
    );
  }

  createExperience(formData) {
    return this.http.post(`${this.apiUrl}jobs/experience/`, formData).pipe(
      tap(() => {
        this.refreshNeeded$.next();
      })
    );
  }

  createInterview(formData) {
    return this.http
      .post(`${this.apiUrl}applications/interview/`, formData)
      .pipe(
        tap(() => {
          this.refreshNeeded$.next();
        })
      );
  }

  jobCreate(formData: any): Observable<Job> {
    return this.http.post<Job>(`${this.apiUrl}jobs/jobs_list/`, formData);
  }

  jobBranch() {
    return this.http.get<any>(`${this.apiUrl}jobs/branches/`);
  }

  jobDivision() {
    return this.http.get<any>(`${this.apiUrl}jobs/divisions/`);
  }

  jobLocation() {
    return this.http.get<any>(`${this.apiUrl}jobs/locations/`);
  }

  jobDepartment() {
    return this.http.get<any>(`${this.apiUrl}jobs/departments/`);
  }

  jobExperience(): Observable<Employment> {
    return this.http.get<any>(`${this.apiUrl}jobs/experience/`);
  }

  jobSkill() {
    return this.http.get<any>(`${this.apiUrl}jobs/skills/`);
  }

  loadJobs(): Observable<Job[]> {
    return this.http.get<Job[]>(`${this.apiUrl}jobs/jobs_list`);
  }

  loadJobById(id: number): Observable<Job> {
    if (id === 0) {
    }
    return this.http
      .get<Job>(`${this.apiUrl}jobs/jobs_list/${id}`)
      .pipe(catchError(this.errorHandlerService.handleError));
  }

  loadCandidateByJobId(id: any) {
    return this.http.get<any>(
      `${this.apiUrl}applications/candidates/?job__id=${id}`
    );
  }

  loadAllCandidatePerJob(id: any) {
    return this.http.get<any>(`${this.apiUrl}jobs/${id}/candidates`);
  }

  filterCandidatesByScore(id: any, min: number, max: number) {
    return this.http.get<any>(
      `${this.apiUrl}jobs/${id}/candidates/?start=${min}&end=${max}`
    );
  }

  deleteJob(id: any): Observable<Job> {
    return this.http.delete<Job>(`${this.apiUrl}jobs/jobs_list/${id}`).pipe(
      tap(() => {
        this.refreshNeeded$.next();
      })
    );
  }

  updateJob(id: any, formData): Observable<Job> {
    console.log(id);
    return this.http
      .patch<Job>(`${this.apiUrl}jobs/jobs_list/${id}/`, formData)
      .pipe(
        tap(() => {
          this.refreshNeeded$.next();
        })
      );
  }

  loadCandidateDetails(id: any) {
    return this.http.get<any>(`${this.apiUrl}applications/applications/${id}`);
  }

  fetchCompany() {
    return this.http.get<any>(`${this.apiUrl}jobs/company`);
    // return this.http.get<any>(`${this.arpApiUrl}company`);
  }

  fetchDivision() {
    return this.http.get<any>(`${this.apiUrl}jobs/divisions`);
  }

  fetchDepartment() {
    return this.http.get<any>(`${this.apiUrl}jobs/departments`);
  }

  fetchInterviews() {
    return this.http.get<any>(`${this.apiUrl}applications/interview`);
  }

  fetchCandidates() {
    return this.http.get<any>(`${this.apiUrl}applications/applications/`);
  }
}
