<app-nav-header></app-nav-header>

<section class="body">
  <div class="container-fluid">

    <app-side-nav></app-side-nav>

    <div class="main">

      <section class="profile">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col ">
                  <div class="basic__title">
                    <i class="fas fa-file-alt"></i>
                    <h6> Basics </h6>
                  </div>
                  <div class="card">
                    <div class="row">
                      <div class="col">
                        <div class="user__details">
                          <p>Name:</p>
                          <p class="user__detailsInfo" >{{applicantDetails?.first_name}} {{applicantDetails?.last_name}}</p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="user__details">
                          <p>Email:</p>
                          <p class="user__detailsInfo" >{{applicantDetails?.email}}</p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="user__details">
                          <p>Phone Number:</p>
                          <p class="user__detailsInfo" >{{applicantDetails?.phone_number}}</p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="user__details">
                          <p>Linkedin URL:</p>
                          <p class="user__detailsInfo"><a href="{{applicantDetails?.linkedIn_URL}}">{{applicantDetails?.linkedIn_URL}}</a></p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="user__details">
                          <p>Personal Website:</p>
                          <p class="user__detailsInfo"><a href="{{applicantDetails?.personal_website_URL}}">{{applicantDetails?.personal_website_URL}}</a></p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="user__details">
                          <p>Github URL:</p>
                          <p class="user__detailsInfo"><a href="{{applicantDetails?.gitHub_URL}}">{{applicantDetails?.gitHub_URL}}</a></p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="user__details">
                          <p>Location:</p>
                          <p class="user__detailsInfo" >{{applicantDetails?.location}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 mt-4">
                  <div class="card">
                    <div class="card-header">
                      <div class="basic__title">
                        <i class="far fa-file"></i>
                        <h6> Resume </h6>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="password">
                        <a *ngIf="applicantDetails?.resume === null" href="{{applicantDetails?.resume}}" class="cool">No Resume</a>
                        <a *ngIf="applicantDetails?.resume !== null" href="{{applicantDetails?.resume}}" class="cool">Click here to view</a>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="col-md-6 mt-4">
                  <div class="card">
                    <div class="card-header">
                      <div class="basic__title">
                        <i class="fas fa-comment-alt"></i>
                        <h6> Cover Letter </h6>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="password">
                        <a *ngIf="applicantDetails?.cover_letter === null" href="{{applicantDetails?.cover_letter}}" class="cool">No Cover letter</a>
                        <a *ngIf="applicantDetails?.cover_letter !== null" href="{{applicantDetails?.cover_letter}}" class="cool">Click here to view</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



              <div class="row">
                <div class="col-md-12 ">
                  <div class="basic__title">
                    <i class="fas fa-pen-fancy"></i>
                    <h6> Education </h6>
                  </div>


                  <div class="card">
                    <div *ngIf="applicantDetails?.education.length > 0">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">No.</th>
                            <th scope="col">Institution</th>
                            <th scope="col">Field of study</th>
                            <th scope="col">Start Date</th>
                            <th scope="col">End Date</th>
                            <th scope="col">Certificate</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let detail of applicantDetails?.education; index as i">
                            <td>{{i + 1}}</td>
                            <td>{{detail.institution}}</td>
                            <td>{{detail.field_of_study}}</td>
                            <td>{{detail.start_date}}</td>
                            <td>{{detail.end_date}}</td>
                            <td>{{detail.certificate}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="password" *ngIf="applicantDetails?.education.length === 0">
                      <p>No education history</p>
                    </div>
                  </div>
                </div>
              </div>


              <div class="row p-2">
                <div class="col-md-12 ">
                  <div class="basic__title">
                    <i class="fas fa-briefcase"></i>
                    <h6> Employment History </h6>
                  </div>
                  <div class="card">
                    <div *ngIf="applicantDetails?.employer.length > 0">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">No.</th>
                            <th scope="col">Company</th>
                            <th scope="col">Job Title</th>
                            <th scope="col">Start Date</th>
                            <th scope="col">End Date</th>
                            <th scope="col">Summary</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let detail of applicantDetails?.employer; index as i">
                            <td>{{i + 1}}</td>
                            <td>{{detail.company}}</td>
                            <td>{{detail.job_title}}</td>
                            <td>{{detail.start_date}}</td>
                            <td>{{detail.end_date}}</td>
                            <td>{{detail.summary}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="password" *ngIf="applicantDetails?.employer.length === 0">
                      <p>No employment history</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6 pt-4">
              <div class="basic__title">
                <h6>Set Interview Details</h6>
              </div>
              <div class="card info-form">

                <form action="" [formGroup]="form">
                  <div class="form-group">
                    <label>Recruiter</label>
                    <input class="form-control" type="text" name="" value="" placeholder="Recruiter" formControlName="recruiter">
                  </div>
                  <div class="form-group">
                    <label>Interview Date</label>
                    <input class="form-control" type="date" placeholder="date" formControlName="interview_date" required>
                  </div>
                  <div class="form-group">
                    <label>Interview Time</label>
                    <input class="form-control" type="time" placeholder="time" formControlName="interview_time" required>
                  </div>
                  <div class="form-group">
                    <label>Select Application status</label>
                    <select formControlName="status" class="form-select form-control">
                      <option disabled value="" hidden [selected]=true class="selected">Status</option>
                      <option *ngFor="let status of statuses" value="{{status}}" >{{status}}</option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label>Recruiter notes</label>
                    <textarea class="form-control" formControlName="recruiter_notes"></textarea>
                  </div>
                </form>

                <button type="button" name="button" class="btn btn-primary m-2" (click)="submit()">submit</button>
              </div>

            </div>
          </div>


        </div>
      </section>


    </div>
  </div>

</section>
